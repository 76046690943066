import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import baseURL from 'utils/base-url';
const instance = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

function getCurrentURL() {
  const url = window.location.href;
  const urlParts = new URL(url);
  return urlParts.pathname;
}

instance.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token: string | null = localStorage.getItem('token') || null;

  let headers = config.headers ?? {};

  if (token) headers.Authorization = `Bearer ${token}`;

  const newConfig: InternalAxiosRequestConfig = {
    ...config,
    headers,
  };

  return newConfig;
});

instance.interceptors.response.use(
  (response: AxiosResponse) => response,
  (error: AxiosError) => {
    if (error.code === 'ERR_NETWORK') alert('Ошибка на стороне сервера');
    // else if (error.response?.status === 422) {
    // (error.response.data as any)?.detail.forEach((log: any) => {
    //   toast.error(log.msg);
    // });
    // }
    else if (error.response?.status === 403 || error.response?.status === 401) {
      const current = getCurrentURL();
      if (current !== '/login') {
        localStorage.removeItem('token');
        window.location.replace('/login');
      }
    }
    return Promise.reject(error);
  },
);

export const request = instance;

export default request;
