import { Models, RematchDispatch, RematchRootState } from '@rematch/core';
import application from './application';
import { cities } from './cities';
import user from './user';

export interface IRootModel extends Models<IRootModel> {
  application: typeof application;
  user: typeof user;
  cities: typeof cities;
}
export type TDispatch = RematchDispatch<IRootModel>;
export type TRootState = RematchRootState<IRootModel>;
export const models: IRootModel = { application, user, cities };
