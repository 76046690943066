import { useLayoutEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'store/hooks';
import Navbar from 'components/navbar';
import Loading from 'components/loading';
import styles from './application.module.scss';

const ApplicationLayout = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const contextApplication = useSelector((state) => state.application);
  const contextCities = useSelector((state) => state.cities);

  useLayoutEffect(() => {
    dispatch.application.FETCH_APPLICATION_BY_ID(id);
  }, [dispatch.application, id]);

  useLayoutEffect(() => {
    dispatch.cities.FETCH_CITIES();
  }, [dispatch.cities]);

  return (
    <div className={styles.applicationLayout} id="application-layout">
      {(contextCities.pending || contextApplication.pending) && (
        <div className={styles.loading}>
          <Loading />
        </div>
      )}
      {!contextApplication.pending && (
        <div className={styles.container}>
          <div className={styles.navbar}>
            <Navbar resultBlocked={contextApplication.data?.scoring_log === null} />
          </div>
          <div className={styles.content}>
            <Outlet />
          </div>
        </div>
      )}
    </div>
  );
};

export default ApplicationLayout;
