import { createModel } from '@rematch/core';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import request from 'request';

export type Cities = { key: string; value: string };

interface InitialState {
  pending: boolean;
  data: Cities[];
  cityValue: string;
}

const initialValue: InitialState = {
  pending: false,
  data: [],
  cityValue: '',
};

export const cities = createModel()({
  state: initialValue as InitialState,
  reducers: {
    START_PENDING(state) {
      return { ...state, pending: true };
    },
    STOP_PENDING(state) {
      return { ...state, pending: false };
    },
    SET(state, payload: Cities[]) {
      if (JSON.stringify(state.data) === JSON.stringify(payload)) return state;
      return { ...state, data: payload };
    },
    REMOVE() {
      return initialValue;
    },
  },
  effects: (dispatch) => ({
    async FETCH_CITIES() {
      dispatch.cities.START_PENDING();
      try {
        const response = await request.get('/validation/get_disctrict_enum');
        const data = response.data as Cities[];
        dispatch.cities.STOP_PENDING();
        dispatch.cities.SET(data);
      } catch (err: unknown) {
        if (err instanceof AxiosError) {
          toast.error('Ошибка при загрузке городов');
          console.error(err);
        }
      }
    },
  }),
});
