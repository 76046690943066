export const districts = [
  {
    key: 'guliston',
    value: 'Гулистон',
  },
  {
    key: 'tabashar',
    value: 'Табошар',
  },
  {
    key: 'mastchoh_kuhi',
    value: 'Мастчохи кухи',
  },
  {
    key: 'khorog',
    value: 'Хорог',
  },
  {
    key: 'shughnan',
    value: 'Шугнан',
  },
  {
    key: 'rushan',
    value: 'Рушан',
  },
  {
    key: 'darvaz',
    value: 'Дарваз',
  },
  {
    key: 'roshtkala',
    value: 'Рошткала',
  },
  {
    key: 'murgab',
    value: 'Мургаб',
  },
  {
    key: 'ishkashim',
    value: 'Ишкашим',
  },
  {
    key: 'vanj',
    value: 'Вандж',
  },
  {
    key: 'lyakhsh',
    value: 'Ляхш',
  },
  {
    key: 'sangvor',
    value: 'Сангвор',
  },
  {
    key: 'norabad',
    value: 'Нурабад',
  },
  {
    key: 'shamsiddin_shohin',
    value: 'Шамсиддин Шохин',
  },
  {
    key: 'jamoliddini_balhi',
    value: 'Джалолиддини Балхи',
  },
  {
    key: 'baljuvon',
    value: 'Балджувон',
  },
  {
    key: 'ayni',
    value: 'Айни',
  },
  {
    key: 'varzob',
    value: 'Варзоб',
  },
  {
    key: 'asht',
    value: 'Ашт',
  },
  {
    key: 'b_gafurov',
    value: 'Б.Гафуров',
  },
  {
    key: 'bokhtar',
    value: 'Бохтар',
  },
  {
    key: 'dangara',
    value: 'Дангара',
  },
  {
    key: 'devashtich',
    value: 'Деваштич',
  },
  {
    key: 'dushanbe',
    value: 'Душанбе',
  },
  {
    key: 'dusti',
    value: 'Дусти',
  },
  {
    key: 'farkhor',
    value: 'Фархор',
  },
  {
    key: 'farovon',
    value: 'Фаровон',
  },
  {
    key: 'hamadoni',
    value: 'Хамадони',
  },
  {
    key: 'hissor',
    value: 'Гиссар',
  },
  {
    key: 'isfara',
    value: 'Исфара',
  },
  {
    key: 'istaravshan',
    value: 'Истаравшан',
  },
  {
    key: 'balkhi',
    value: 'Дж.Балхи',
  },
  {
    key: 'rasulov',
    value: 'Дж.Расулов',
  },
  {
    key: 'jayhun',
    value: 'Джайхун',
  },
  {
    key: 'jomi',
    value: 'Джоми',
  },
  {
    key: 'khujand',
    value: 'Худжанд',
  },
  {
    key: 'khuroson',
    value: 'Хуросон',
  },
  {
    key: 'konibodom',
    value: 'Канибадам',
  },
  {
    key: 'qubodiyon',
    value: 'Кубодийон',
  },
  {
    key: 'kulob',
    value: 'Куляб',
  },
  {
    key: 'mastchoh',
    value: 'Мастчох',
  },
  {
    key: 'muminabad',
    value: 'Муминобод',
  },
  {
    key: 'panj',
    value: 'Пандж',
  },
  {
    key: 'panjakent',
    value: 'Пенджикент',
  },
  {
    key: 'rudaki',
    value: 'Рудаки',
  },
  {
    key: 'shahriston',
    value: 'Шахристон',
  },
  {
    key: 'shahrituz',
    value: 'Шахритуз',
  },
  {
    key: 'somon',
    value: 'Сомон',
  },
  {
    key: 'spitamen',
    value: 'Спитамен',
  },
  {
    key: 'temurmalik',
    value: 'Темурмалик',
  },
  {
    key: 'tursunzoda',
    value: 'Турсунзода',
  },
  {
    key: 'vahdat',
    value: 'Вахдат',
  },
  {
    key: 'vose',
    value: 'Восеъ',
  },
  {
    key: 'yovon',
    value: 'Евон',
  },
  {
    key: 'zafarobod',
    value: 'Зафаробод',
  },
  {
    key: 'norak',
    value: 'Нурек',
  },
  {
    key: 'buston',
    value: 'Бустон',
  },
  {
    key: 'shahrinav',
    value: 'Шахринав',
  },
  {
    key: 'nawkat',
    value: 'Навкат',
  },
  {
    key: 'vakhsh',
    value: 'Вахш',
  },
  {
    key: 'korvon',
    value: 'Корвон',
  },
  {
    key: 'faizabad',
    value: 'Файзобод',
  },
  {
    key: 'nosiri_khusrav',
    value: 'Носири Хусрав',
  },
  {
    key: 'tojikobod',
    value: 'Тоҷикобод',
  },
  {
    key: 'nurobod',
    value: 'Нуробод',
  },
  {
    key: 'rasht',
    value: 'Рашт',
  },
].sort((a, b) => a.value.localeCompare(b.value));
