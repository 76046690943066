import React from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import * as math from 'mathjs';
import { Link } from 'react-router-dom';

import Input from 'components/input';
import Button from 'components/button';
import ButtonLoader from 'components/button-loader';
import Icon from 'icons';
import prettierNumber from 'utils/prettier-number';
import request from 'request';
import modalStyles from './components/create-modal.module.scss';
import Modal from './components/create-modal';
import styles from './home.module.scss';
import home from './home';
import { reverseDate } from 'utils/date-reverse';

const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1);
const lastDayOfYear = new Date(new Date().getFullYear(), 11, 31);

const Home = () => {
  const [from, setFrom] = React.useState<Date>(firstDayOfYear);
  const [to, setTo] = React.useState<Date>(lastDayOfYear);
  const [page, setPage] = React.useState(1);
  const [rowCount, setRowCount] = React.useState(1);
  const [newApplicationModal, setNewApplicationModal] = React.useState(false);
  const [pending, setPending] = React.useState(true);
  // const [exportExcelPending, setExportExcelPending] = useState(false);
  const resultRef = React.useRef<HTMLDivElement | null>(null);

  const [data, setData] = React.useState<home.result[]>([]);

  const handleFilter = async () => {
    const fd = reverseDate(from);
    const td = reverseDate(to);
    setPending(true);
    resultRef.current?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    request
      .get(`/applications/history?fd=${fd}&td=${td}&page=${page}`)
      .then((res) => res.data)
      .then((d) => {
        setData(d.records);
        setRowCount(d.total_count);
      })
      .finally(() => setPending(false));
  };

  const handleClear = async () => {
    setFrom(firstDayOfYear);
    setTo(lastDayOfYear);
    await handleFilter();
  };

  // const handleExportExcel = () => {
  //   const fd = from.toLocaleDateString('en-GB').split('/').reverse().join('-');
  //   const td = to.toLocaleDateString('en-GB').split('/').reverse().join('-');
  //   setExportExcelPending(true);
  //   request({
  //     method: 'POST',
  //     url: `/applications/xlsx?fd=${fd}&td=${td}`,
  //     responseType: 'blob',
  //   })
  //     .then((res) => res.data)
  //     .then((data) => {
  //       const href = URL.createObjectURL(data);
  //       const link = document.createElement('a');
  //       link.href = href;
  //       link.setAttribute('download', fd + ',' + td + '.xlsx');
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       URL.revokeObjectURL(href);
  //     })
  //     .finally(() => setExportExcelPending(false));
  // };

  React.useEffect(() => {
    const handleLoadRows = async () => {
      const fd = reverseDate(firstDayOfYear);
      const td = reverseDate(lastDayOfYear);
      setPending(true);
      resultRef.current?.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      request
        .get(`/applications/history?fd=${fd}&td=${td}&page=${page}`)
        .then((res) => res.data)
        .then((d) => {
          setData(d.records);
          setRowCount(d.total_count);
        })
        .finally(() => setPending(false));
    };
    handleLoadRows();
  }, [page]);

  return (
    <div className={styles.results} ref={resultRef}>
      <div className={styles.container}>
        <div className={styles.tools}>
          <div className={styles.filterBox}>
            <div className={styles.input}>
              <Input title="От" value={from} type="date" onSetDate={(d) => setFrom(d)} />
            </div>
            <div className={styles.input}>
              <Input title="До" value={to} type="date" onSetDate={(d) => setTo(d)} />
            </div>
            <div className={styles.buttonGroup}>
              <Button pending={pending} onClick={handleFilter}>
                Фильтр
              </Button>
              <Button pending={pending} color="red" onClick={handleClear}>
                Сбросить
              </Button>
            </div>
          </div>
          <div className={styles.buttonGroup}>
            {/* <Button
              pending={exportExcelPending || pending}
              color="orange"
              onClick={handleExportExcel}
            >
              Export .XLSX file
            </Button> */}
            <Button color="green" onClick={() => setNewApplicationModal(true)}>
              Новая заявка
            </Button>
          </div>
        </div>
        {pending && (
          <div className={styles.tableLoading}>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
            <div className={styles.tableLoadingRow}>
              <ButtonLoader />
            </div>
          </div>
        )}
        {!pending && data.length !== 0 && (
          <div className={styles.table}>
            {data.map((item) => (
              <Link
                to={`application/${item.id}/scoring`}
                className={styles.tableRowWrap}
                key={item.id}
              >
                <div className={styles.tableRow}>
                  <div className={styles.item}>
                    <span>ID заявка</span> {item.id}
                  </div>
                  <div className={styles.item}>
                    <span>Эксперт</span>
                    {item?.created_by.email}
                  </div>
                  <div className={styles.item}>
                    <span>Дата создания</span>
                    {new Date(item.created_at).toLocaleString()}
                  </div>
                  <div className={styles.item}>
                    <span>Сумма</span>
                    {item.recommendations_log.find((e) => e.is_chosen)
                      ? item.recommendations_log.find((e) => e.is_chosen)?.amount
                      : prettierNumber(item.amount)}{' '}
                    TJS
                  </div>
                  <div className={styles.item}>
                    <span>Длительность</span>
                    {item.duration} мес.
                  </div>
                  <div className={styles.item}>
                    <span>Процентная ставка</span>
                    {Math.round(item.int_rate)}%
                  </div>
                  <div
                    className={classNames(
                      styles.item,
                      item.is_approved && styles.green,
                      !item.is_approved && item.scoring_log && styles.red,
                    )}
                  >
                    <span>Результат</span>
                    {item.scoring_log === null
                      ? 'Неизвестно'
                      : item.is_approved
                      ? 'Одобрено'
                      : 'Отказано'}
                  </div>
                  <div className={styles.item}>
                    <span>Вероятность одобрения</span>
                    {item.recommendations_log.find((e) => e.is_chosen)
                      ? (item.recommendations_log.find((e) => e.is_chosen)!.prob * 100).toFixed(1) +
                        '%'
                      : item.scoring_log?.prediction
                      ? `${(item.scoring_log?.prediction * 100).toFixed(2)}%`
                      : '–'}
                  </div>
                  <div className={styles.item}>
                    <span>Порог</span>
                    {item.scoring_log?.threshold ? `${item.scoring_log?.threshold * 100}%` : '–'}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
        {!pending && data.length === 0 && <div className={styles.tableIsEmptyInfo}>Нет заявок</div>}
        {rowCount > 15 && (
          <div className={styles.pagination}>
            <button
              className={classNames(styles.arrow, page <= 1 && styles.disabled)}
              onClick={() => setPage(math.max(page - 1, 1))}
            >
              <Icon name="arrowLeft" />
            </button>
            <div className={styles.label}>
              {page} / {math.round(rowCount / 15)}
            </div>
            <button
              className={classNames(styles.arrow, page * 15 >= rowCount && styles.disabled)}
              onClick={() => setPage(math.min(page + 1, math.round(rowCount / 15)))}
            >
              <Icon name="arrowRight" />
            </button>
          </div>
        )}
      </div>
      <CSSTransition
        in={newApplicationModal}
        timeout={150}
        unmountOnExit
        classNames={{
          enter: modalStyles.modalEnter,
          enterActive: modalStyles.modalEnterActive,
          exit: modalStyles.modalExit,
          exitActive: modalStyles.modalExitActive,
        }}
      >
        <Modal onClose={() => setNewApplicationModal(false)} />
      </CSSTransition>
    </div>
  );
};

export default Home;
