import { FunctionComponent, useEffect, useRef, useState } from 'react';
import styles from './typewriter-text.module.scss';

interface TypewriterTextProps {
  text: string;
  speed?: number;
  id: number | string;
}

// id 29
export const TypewriterText: FunctionComponent<TypewriterTextProps> = ({
  text,
  speed = 50,
  id,
}) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isInView, setIsInView] = useState(false);
  const [isCompeleted, setIsCompleted] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const viewedItems = localStorage.getItem('itemId');
    if (viewedItems) {
      const viewedItemsArray = viewedItems.split(',').map((item) => item.trim());
      if (viewedItemsArray.includes(String(id))) {
        setDisplayedText(text);
        setCurrentIndex(text.length);
        setIsCompleted(true);
      }
    }
  }, [id, text]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true);

          observer.disconnect();
        }
      },
      {
        threshold: 0.2,
        rootMargin: '50px',
      },
    );

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    if (isInView && currentIndex < text.length) {
      const timer = setTimeout(() => {
        setDisplayedText((prev) => prev + text[currentIndex]);
        setCurrentIndex((prev) => prev + 1);

        if (currentIndex === text.length - 1) {
          try {
            const viewedItems = localStorage.getItem('itemId');
            const newValue = viewedItems ? `${viewedItems},${id}` : String(id);

            const uniqueIds = [...new Set(newValue.split(','))].join(',');
            localStorage.setItem('itemId', uniqueIds);

            setIsCompleted(true);
          } catch (error) {
            console.error('Error saving to localStorage:', error);
          }
        }
      }, speed);

      return () => clearTimeout(timer);
    }
  }, [currentIndex, text, speed, isInView, id, isCompeleted]);

  return (
    <div ref={textRef} style={{ whiteSpace: 'pre-line' }}>
      {displayedText}
      {!isCompeleted && <span className={styles.cursor}></span>}
    </div>
  );
};
